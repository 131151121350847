import { jsPDF } from "jspdf";

export default class TicketReceipt {
  print({ fecha, concepto, total } = {}, format = "") {
    console.log(fecha, concepto, total);

    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "letter",
    });

    const font = doc.getFont().fontName;
    doc.setFontSize(11);
    doc.setFont(font, "bold");

    // Formato
    doc.addImage(format, "png", 0, 0, 215.9, 279.4);
    doc.text("CONCEPTO", 14, 59);
    doc.text("TOTAL", 122, 59);

    // concepto
    doc.text(concepto.toString(), 14, 75, { maxWidth: 88 });

    // Total
    const _total = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(total);
    doc.text(_total, 122, 75);

    doc.setTextColor("#B40048");
    doc.setFontSize(10)

    doc.text(
      "Para cualquier duda o aclaración por favor precentar este recibo",
      55,
      145
    );

    doc.output("dataurlnewwindow", {
      filename: `recibo.pdf`,
    });
  }
}
