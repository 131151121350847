export const FormatsMixin = {
  methods: {
    getFormatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
    getShortDate(date) {
      return new Intl.DateTimeFormat("es-MX", {
        dateStyle: "medium",
      }).format(new Date(date));
    },
    getTime(date) {
      const dateNew = new Date(date);
      return new Intl.DateTimeFormat("es-MX", {
        timeStyle: "medium",
        hour12: true,
      }).format(dateNew);
    },
  },
};
