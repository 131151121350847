import Service from "./config/ConfigRepositoryAuth";

export default {
  async create(receipt) {
    return await Service.post(`receipts`, receipt);
  },
  async getAll() {
    return await Service.get(`receipts`);
  },
};
