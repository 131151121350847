import { jsPDF } from "jspdf";

export default class TicketInternetMovil {
  print({ client, dateCreated, days, price, status }, logo = "") {
    console.log(dateCreated, days, price, status);
    let doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: [58, 100],
    });

    const font = doc.getFont().fontName;

    doc.setFontSize(8);

    // Alto 25% de ancho
    doc.addImage(logo, "png", 9, 10, 40, 10);

    // Fecha
    const date = new Intl.DateTimeFormat("es-MX", {
      dateStyle: "medium",
      timeStyle: "short",
      hour12: true,
    })
      .format(new Date(dateCreated))
      .toString()
      .toUpperCase();

    doc.text(date, 29, 30, {
      align: "center",
      maxWidth: 55,
    });

    // Nombre de cliente
    doc.setFontSize(9);
    doc.setFont(font, "bold");
    doc.text(client.toUpperCase(), 29, 36, {
      align: "center",
      maxWidth: 55,
    });

    // Vigencia
    doc.setFontSize(10);
    const vigencia = `${days} días`;

    doc.text("Vigencia", 29, 45, {
      align: "center",
      maxWidth: 55,
    });

    doc.setFont(font, "normal");
    doc.text(vigencia, 29, 50, {
      align: "center",
      maxWidth: 55,
    });

    // Total
    const total = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(price);

    doc.setFontSize(10);
    doc.setFont(font, "bold");
    doc.text("Total", 29, 65, {
      align: "center",
      maxWidth: 55,
    });

    doc.setFont(font, "normal");
    doc.text(total, 29, 70, {
      align: "center",
      maxWidth: 55,
    });

    // Total
    doc.setFontSize(10);
    doc.setFont(font, "bold");
    doc.text(status.toUpperCase(), 29, 85, {
      align: "center",
      maxWidth: 55,
    });

    doc.output("dataurlnewwindow", {
      filename: `ticket.pdf`,
    });
  }
}
