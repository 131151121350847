<template>
  <div class="ReciboComponent">
    <v-card v-if="isNew">
      <v-card-text>
        <v-form @submit.prevent="save()" v-model="isValid">
          <div class="header text-h5 mb-3">Crear nuevo recibo</div>

          <v-textarea
            v-model="recibo.concepto"
            label="Descripción"
            outlined
            dense
            :rules="[rules.requierd]"
          ></v-textarea>
          <v-text-field
            v-model="recibo.total"
            type="number"
            label="Total"
            outlined
            dense
            :rules="[rules.requierd, rules.moreThanZero]"
          />
          <div class="actions-buttons">
            <v-btn @click="isNew = false" outlined small color="primary" depressed
              >Cancelar</v-btn
            >
            <v-btn :disabled="!isValid" type="submit" small color="primary" depressed>Guardar</v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-text class="header text-h5">
        Recibos
        <v-btn @click="isNew = true" small color="primary" depressed dark
          >Generar recibo</v-btn
        >
      </v-card-text>
      <v-card-text>
        <v-simple-table>
          <template #default>
            <tbody>
              <tr v-for="(item, idx) in items" :key="idx">
                <td class="text-center">
                  <div class="parrafo" v-html="item.concepto"  :title="item.concepto"/>
                </td>
                <td class="text-center">{{ item.total | formatCurrency }}</td>
                <td class="text-right">
                  <v-btn small icon @click="print(item)">
                    <v-icon color="#d2231a">mdi-file-pdf</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p v-if="items.length === 0" class="text-center mt-2">
          No hay resultados
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
import Receipt from "@/domain/Receipt";
// Tickets
import PrintTicket from "@/helpers/PrintTicket";
export default {
  name: "ReciboComponent",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isNew: false,
    isValid: false,
    recibo: {},
    rules: {
        requierd: v => !!v || "Este campo es requerido",
        moreThanZero: v => v > 0 || "El valor debe ser mayor a 0"
    }
  }),
  methods: {
    save() {
      Receipt.create(this.recibo)
        .then(() => {
          this.recibo = {};
          this.isNew = false;
          this.$emit("created");
        })
        .catch(err => {
          console.error(err);
        });
    },
    print(receipt) {
      PrintTicket.Receipt(receipt);
    }
  }
};
</script>


<style lang="scss" scoped>
.ReciboComponent {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .parrafo {
    height: 1rem;
    width: 100%;
    overflow: hidden;
  }
  .actions-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>