import { jsPDF } from "jspdf";

export default class TicketHomePlan {
  print(
    { id, name, phone, address } = {},
    { datePayment, mount, period } = {},
    format = ""
  ) {
    console.log(id, name, phone, address, datePayment, mount, period);

    const doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "letter",
    });

    const font = doc.getFont().fontName;
    doc.setFontSize(11);
    doc.setFont(font, "bold");

    // Formato
    doc.addImage(format, "png", 0, 0, 215.9, 279.4);

    // Número de cliente
    doc.text(id.toUpperCase(), 14, 86);

    // Fecha de pago
    const date = new Intl.DateTimeFormat("es-MX", {
      dateStyle: "medium",
      timeStyle: "short",
      hour12: true,
    })
      .format(new Date(datePayment))
      .toString()
      .toUpperCase();
    doc.text(date, 121, 86);

    // Nombre de cliente
    doc.text(name.toUpperCase(), 14, 98);

    // Periodo
    doc.text(period.toUpperCase(), 121, 99);

    // Numero de telefono
    doc.text(phone.toString(), 14, 110);

    // Pagado
    const price = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(mount);
    doc.text(price, 121, 112);

    // direccion
    doc.text(address.toUpperCase(), 14, 123, {
      maxWidth: 80,
    });

    doc.output("dataurlnewwindow", {
      filename: `ticket.pdf`,
    });
  }
}
