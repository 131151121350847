import TicketInternetMovil from "./ticketInternetMovil";
import TicketHomePlan from "./TicketHomePlan";
import TicketReceipt from "./TicketReceipt";
import { logo } from "./logo";
import { formatHomePlan } from "./formatHomePlan";
import { formatReceipt } from "./formatReceipt";

export default class PrintTicket {
  static InternetMovil(ticketInfo = {}) {
    const internetMovil = new TicketInternetMovil();
    internetMovil.print(ticketInfo, logo);
  }

  static HomePlan(client = {}, payment = {}) {
    const homePlan = new TicketHomePlan();
    homePlan.print(client, payment, formatHomePlan);
  }

  static Receipt(receipt = {}) {
    const ticketReceipt = new TicketReceipt();
    ticketReceipt.print(receipt, formatReceipt);
  }
}
