import ReceiptRepository from "../repository/Receipt.repository";

export default class Receipts {
  static async getAll() {
    return new Promise((resolve, reject) => {
      ReceiptRepository.getAll()
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static async create(receipt) {
    return new Promise((resolve, reject) => {
      ReceiptRepository.create(receipt)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}