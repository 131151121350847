<template>
  <v-container>
    <v-progress-linear v-if="isLoading" indeterminate />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="9">
            <h2>Total del corte de caja</h2>
            <h1 class="mt-2">
              {{ total | formatCurrency }}
            </h1>
          </v-col>
          <v-col cols="12" md="3" class="d-flex justify-end">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="input-date" color="primary" outlined dense hide-details v-model="date"
                  label="Fecha de corte" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable @change="cambio()" />
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="mt-4">
      <v-col cols="12" md="4">
        <v-card>
          <v-card-text>
            <div class="d-flex text-h5">
              Usuarios Temporales
              <v-spacer />
              {{ totalPlanTemporal | formatCurrency }}
            </div>
          </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr v-for="plan of planTemporal" :key="plan._id">
                    <td class="text-left">{{ getCode(plan._id) }}</td>
                    <td class="text-center">{{ plan.idClient.name }}</td>
                    <td class="text-center">{{ plan.status }}</td>
                    <td class="text-right">{{ plan.mount | formatCurrency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p v-if="planTemporal.length === 0" class="text-center mt-2">No hay resultados</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-text class="d-flex text-h5">
            Usuarios con Plan
            <v-spacer />
            {{ totalPlanHogar | formatCurrency }}
          </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr v-for="plan of planHome" :key="plan._id">
                    <td class="text-left">{{ getCode(plan._id) }}</td>
                    <td class="text-center">{{ plan.idClient.name }}</td>
                    <td class="text-center">{{ plan.status }}</td>
                    <td class="text-right">{{ plan.mount | formatCurrency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p v-if="planHome.length === 0" class="text-center mt-2">No hay resultados</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <recibos-component @created="getCorteCaja" :items="recibos" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Sale from "@/domain/Sales";
import RecibosComponent from './RecibosComponent.vue';

// Mixins
import { FormatsMixin } from "../../mixins/Formats";

export default {
  components: { RecibosComponent },
  name: "CorteCaja",
  mixins: [FormatsMixin],
  mounted() {
    const date = new Date();

    const formattedDate = date.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/Mexico_City'
    });

    const formattedTime = date.toLocaleTimeString('en-CA', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit', 
      hour12: false,
      timeZone: 'America/Mexico_City'
    });

    console.log(formattedDate, formattedTime);

    this.getCorteCaja();
  },
  data: () => ({
    date: new Date().toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/Mexico_City'
    }),

    menu: false,
    isLoading: false,
    planHome: [],
    planTemporal: [],
    total: 0,
    recibos: []
  }),
  computed: {
    totalPlanHogar() {
      return this.planHome.reduce((acc, cur) => acc + cur.mount, 0)
    },
    totalPlanTemporal() {
      return this.planTemporal.reduce((acc, cur) => acc + cur.mount, 0)
    },
    dateFormat() {
      return this.date.split('-').reverse().join('-');
    }
  },
  methods: {
    async getCorteCaja() {
      try {
        this.isLoading = true;

        const { receipts, planHome, temporalUsers, total } = await Sale.CorteCaja(this.date);
        console.log({
          date: this.date.toString(),
          receipts,
          planHome,
          temporalUsers,
          total
        });
        this.total = total;
        this.planHome = planHome;
        this.planTemporal = temporalUsers;
        this.recibos = receipts;

        console.log({
          date: this.date.toString(),
          receipts,
          planHome,
          temporalUsers,
          total
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.isLoading = false;
      }
    },
    cambio() {
      this.getCorteCaja();
      this.$refs.menu.save(this.date);
    },
    getCode(_id) {
      return _id.slice(-5);
    }
  }
};
</script>


<style lang="scss" scoped>
.input-date {
  width: 200px;
}

.plan {
  width: 100%;

  &__item {
    padding: .5rem 1rem;
    border-radius: .3rem;
    margin-bottom: .5rem;
    background-color: rgb(241, 241, 241);
  }
}
</style>
