import SaleRepository from "../repository/Sales.repository";

export default class Antenna {
  static async CorteCaja(date) {
    return new Promise((resolve, reject) => {
      SaleRepository.corteCaja(date)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
